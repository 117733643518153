import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AdminDashboardMain from './AdminDashboardMain'
import { Link } from 'react-router-dom'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Filter from './Filter'
import BackBtn from './BackBtn'
import Loader from './Loader'
import dayjs from 'dayjs'
import { formatNumberWithPostfix } from '../utils/ConvertNumbers'


const Cases = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [listOfcases, setListOfCases] = useState([])

  const onDateChange=(fromDate , toDate)=>{
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/getCases?fromDate=${fromDate}&toDate=${toDate}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setListOfCases(result?.data)
      })
      .catch(error => console.log('error', error));
  }

  const ListOfCases = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch("https://bizfinn.co.in/getCases", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setListOfCases(result?.data)
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    ListOfCases()
    console.log("listOfcases", listOfcases)
  }, []);



  return (
    <>
      <AdminDashboardMain>
        <section className="side_content_main_box">
          <div className="page_heading_div">
            <div className="back_btn_filter_main">
              <div className="back_btn_filter_inner">
                <BackBtn />
                <h2>Cases</h2>
              </div>
              <Filter onDateChange={onDateChange}/>
            </div>
            <div className="dashboard_add_new_btn">
              <Link className="" to="/add-case">
                Add Cases
              </Link>
            </div>
          </div>

          <section className="tabs_main_section commaon_tabs_main_section">
            <Tabs
              defaultActiveKey="ReceivedList"
              id="uncontrolled-tab-example"
              className="commaon_tabs_main"
            >
              <Tab eventKey="ReceivedList" title="List of Received">
                <section className="table_main_section">
                  <div className="table-responsive">
                    <table className="table   commaon_table">
                      <thead className="table_head">
                        <tr>
                          <th scope="col">Case No</th>
                          <th scope="col">Date</th>
                          <th scope="col">Borrower Name</th>
                          <th scope="col">Type of Loan</th>
                          <th scope="col">Turnover</th>
                          <th scope="col">Loan Ask</th>
                          <th scope="col">Loan Approve</th>
                          <th scope="col">Status</th>
                          <th scope="col">Select Lender</th>
                          <th scope="col">Lender Remark</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && (
                          <div className='pos_abs'>
                            <Loader />
                          </div>
                        )}
                        {listOfcases
                          ?.filter(currEle => currEle?.status === 0)
                          .slice()
                          .reverse()
                          .map(currEle => {
                            return (
                              <>
                                <tr key={currEle?._id}>
                                  <td className="name">
                                    <Link to={`/cases/case-details/${currEle?._id}`}> {currEle?.case_no} </Link>
                                  </td>
                                  <td className="name">
                                    {dayjs(currEle?.createdAt).format('DD-MM-YYYY')}
                                  </td>
                                  <td className="name">
                                    <Link to={`/borrower/borrower-profile/${currEle?.borrower}`}> {currEle?.borrowerName} </Link>
                                  </td>
                                  <td>{currEle?.type_of_loan}</td>
                                  <td> {currEle?.borrowerTurnOver}</td>
                                  <td>{formatNumberWithPostfix(currEle?.requirement)} </td>
                                  <td>
                                     {formatNumberWithPostfix(currEle?.approved_amount)}
                                  </td>
                                  <td>
                                    <span className="table_pending_btn"> Pending</span>
                                  </td>
                                  <td>
                                    {currEle?.lenders.map(currLender => (
                                      <span key={currLender?._id}>{currLender?.landerName}, </span>
                                    ))}
                                  </td>
                                  <td>{currEle?.lender_remark}</td>
                                </tr>

                              </>
                            )
                          })}

                        {listOfcases?.filter(currEle => currEle?.status === 0).length === 0 && (
                          <tr>
                            <td colSpan="8">Data not found</td>
                          </tr>
                        )}

                      </tbody>
                    </table>
                  </div>
                </section>
              </Tab>
              <Tab eventKey="ProgressList" title="List of In Progress">
                <section className="table_main_section">
                  <div className="table-responsive">
                    <table className="table   commaon_table">
                      <thead className="table_head">
                        <tr>
                          <th scope="col">Case No</th>
                          <th scope="col">Date</th>
                          <th scope="col">Borrower Name</th>
                          <th scope="col">Type of Loan</th>
                          <th scope="col">Turnover</th>
                          <th scope="col">Loan Ask</th>
                          <th scope="col">Loan Approve</th>
                          <th scope="col">Status</th>
                          <th scope="col">Select Lender</th>
                          <th scope="col">Lender Remark</th>
                        </tr>
                      </thead>


                      <tbody>
                        {isLoading && (
                          <div className='pos_abs'>
                            <Loader />
                          </div>
                        )}
                        {listOfcases?.filter(currEle => currEle?.status === 3)
                          .slice()
                          .reverse()
                          .map(currEle => {
                            return (
                              <>
                                <tr key={currEle?._id}>
                                  <td className="name">
                                    <Link to={`/case-details/${currEle?._id}`}> {currEle?.case_no} </Link>
                                  </td>
                                  <td className="name">
                                    {dayjs(currEle?.createdAt).format('DD-MM-YYYY')}
                                  </td>
                                  <td className="name">
                                    <Link to={`/borrower-profile/${currEle?.borrower}`}> {currEle?.borrowerName} </Link>
                                  </td>
                                  <td>{currEle?.type_of_loan}</td>
                                  <td> {currEle?.borrowerTurnOver}</td>
                                  <td>{formatNumberWithPostfix(currEle?.requirement)} </td>
                                  <td>
                                     {formatNumberWithPostfix(currEle?.approved_amount)}
                                  </td>
                                  <td>
                                    <span className="table_pending_btn table_progress_btn">
                                      Progress
                                    </span>
                                  </td>
                                  <td>
                                    {currEle?.lenders.map(currLender => ( // Use .lenders to map over the array of objects
                                      <span key={currLender?._id}>{currLender?.landerName}, </span>
                                    ))}
                                  </td>
                                  {/* <td>{currEle?.lender}</td> */}
                                  <td>{currEle?.lender_remark}</td>
                                </tr>
                              </>
                            )
                          })}
                        {(!listOfcases || listOfcases?.filter(currEle => currEle?.status === 3).length === 0) && (
                          <tr>
                            <td colSpan="7">Data not found</td>
                          </tr>
                        )}

                      </tbody>

                    </table>
                  </div>
                </section>
              </Tab>
              <Tab eventKey="ApprovedList" title="List of Approved">
                <section className="table_main_section">
                  <div className="table-responsive">
                    <table className="table   commaon_table">
                      <thead className="table_head">
                        <tr>
                          <th scope="col">Case No</th>
                          <th scope="col">Date</th>
                          <th scope="col">Borrower Name</th>
                          <th scope="col">Type of Loan</th>
                          <th scope="col">Turnover</th>
                          <th scope="col">Loan Ask</th>
                          <th scope="col">Loan Approve</th>
                          <th scope="col">Status</th>
                          <th scope="col">Select Lender</th>
                          <th scope="col">Lender Remark</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && (
                          <div className='pos_abs'>
                            <Loader />
                          </div>
                        )}

                        {listOfcases
                          ?.filter(currEle => currEle?.status === 1)
                          .slice()
                          .reverse()
                          .map(currEle => {
                            return (
                              <>
                                <tr key={currEle?._id}>
                                  <td className="name">
                                    <Link to={`/case-details/${currEle?._id}`}> {currEle?.case_no} </Link>
                                  </td>
                                  <td className="name">
                                    {dayjs(currEle?.createdAt).format('DD-MM-YYYY')}
                                  </td>
                                  <td className="name">
                                    <Link to={`/borrower-profile/${currEle?.borrower}`}> {currEle?.borrowerName} </Link>
                                  </td>
                                  <td>{currEle?.type_of_loan}</td>
                                  <td> {currEle?.borrowerTurnOver}</td>
                                  <td>{formatNumberWithPostfix(currEle?.requirement)} </td>
                                  <td className="name">
                                     {formatNumberWithPostfix(currEle?.approved_amount)}
                                  </td>
                                  <td>
                                    <span className="table_pending_btn table_approved_btn">
                                      Approved
                                    </span>
                                  </td>
                                  <td>
                                    {currEle?.lenders.map(currLender => (
                                      <span key={currLender?._id}>{currLender?.landerName}, </span>
                                    ))}
                                  </td>
                                  <td>{currEle?.lender_remark}</td>
                                </tr>
                              </>
                            )
                          })}

                        {listOfcases?.filter(currEle => currEle?.status === 1).length === 0 && (
                          <tr>
                            <td colSpan="8">Data not found</td>
                          </tr>
                        )}

                      </tbody>
                    </table>
                  </div>
                </section>
              </Tab>
              <Tab eventKey="RejectedList" title="List of Rejected">
                <section className="table_main_section">
                  <div className="table-responsive">
                    <table className="table   commaon_table">
                      <thead className="table_head">
                        <tr>
                          <th scope="col">Case No</th>
                          <th scope="col">Date</th>
                          <th scope="col">Borrower Name</th>
                          <th scope="col">Type of Loan</th>
                          <th scope="col">Turnover</th>
                          <th scope="col">Loan Ask</th>
                          <th scope="col">Loan Approve</th>
                          <th scope="col">Status</th>
                          <th scope="col">Select Lender</th>
                          <th scope="col">Lender Remark</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && (
                          <div className='pos_abs'>
                            <Loader />
                          </div>
                        )}
                        {listOfcases?.filter(currEle => currEle?.status === 2)
                          .slice()
                          .reverse()
                          .map(currEle => {
                            return (
                              <>
                                <tr key={currEle?._id}>
                                  <td className="name">
                                    <Link to={`/case-details/${currEle?._id}`}> {currEle?.case_no} </Link>
                                    {/* {currEle?.case_no} */}
                                  </td>
                                  <td className="name">
                                    {dayjs(currEle?.createdAt).format('DD-MM-YYYY')}
                                  </td>
                                  <td className="name">
                                    <Link to={`/borrower-profile/${currEle?.borrower}`}> {currEle?.borrowerName} </Link>
                                  </td>
                                  <td>{currEle?.type_of_loan}</td>
                                  <td> {currEle?.borrowerTurnOver}</td>
                                  <td>{formatNumberWithPostfix(currEle?.requirement)} </td>
                                  <td>
                                     {formatNumberWithPostfix(currEle?.approved_amount)}
                                  </td>
                                  <td>
                                    <span className="table_pending_btn ">
                                      Rejected
                                    </span>
                                  </td>
                                  <td>
                                    {currEle?.lenders.map(currLender => (
                                      <span key={currLender?._id}>{currLender?.landerName}, </span>
                                    ))}
                                  </td>
                                  <td>{currEle?.lender_remark}</td>
                                </tr>
                              </>
                            )
                          })}
                        {listOfcases?.filter(currEle => currEle?.status === 2).length === 0 && (
                          <tr>
                            <td colSpan="8">Data not found</td>
                          </tr>
                        )}



                      </tbody>
                    </table>
                  </div>
                </section>
              </Tab>
              
            </Tabs>
          </section>
        </section>
      </AdminDashboardMain>
    </>
  )
}

export default Cases
