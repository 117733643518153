import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import AdminDashboardMain from './AdminDashboardMain'
import { Link } from 'react-router-dom'
import Filter from './Filter'
import BackBtn from './BackBtn'
import { ToastContainer, toast } from 'react-toastify'
import Loader from './Loader'
import search from '../assets/imgs/icosn/search.svg'

const Lenders = () => {
  const [show, setShow] = useState(false)
  const [searchQuery, setSearchQuery] = useState('');
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [selectedLenderId, setSelectedLenderId] = useState(null)

  const [lenderlist, setLenderList] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  // List Of lenders API Call
  const LenderList = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    }
    setIsLoading(true)
    fetch('https://bizfinn.co.in/getLeanders', requestOptions)
      .then(response => response.json())
      .then(result => {
        setLenderList(result)
        setIsLoading(false)
      })
      .catch(error => {
        console.log('error', error)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    LenderList()
  }, [])

  // Open modal and set selected lender ID
  const openDeleteModal = lenderId => {
    setSelectedLenderId(lenderId)
    handleShow()
    // console.log('hello', lenderId)
  }
  // ============Delete Lender API================
  const DeleteLender = lenderID => {
    var requestOptions = {
      method: 'DELETE',
      redirect: 'follow'
    }

    fetch(
      `https://bizfinn.co.in/deleteLeander/${lenderID}`,
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          toast.success(result.msg, {
            autoClose: 2000
          })
          const updatedLenderList = lenderlist.data.filter(
            lender => lender._id !== lenderID
          )
          setLenderList({ ...lenderlist, data: updatedLenderList })
        }
      })
      .catch(error => console.log('error', error))
  }


  // =============================================
  // ============table data search================
  // =============================================
  const filteredData = lenderlist?.data
    ?.filter(currEle =>
      currEle.name.toLowerCase().includes(searchQuery.toLowerCase())
    )
    ?.slice()
    .reverse() || [];

    const onDateChange = (fromDate , toDate) => {
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      }
      setIsLoading(true)
  
      fetch(`https://bizfinn.co.in/getLeanders?fromDate=${fromDate}&toDate=${toDate}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setLenderList(result)
        setIsLoading(false)
      })
      .catch(error => {
        console.log('error', error)
        setIsLoading(false)
      })
    }

  return (
    <>
      <AdminDashboardMain>
        <section className='side_content_main_box'>
          <div className='page_heading_div'>
            <div className='back_btn_filter_main'>
              <div className='back_btn_filter_inner'>
                <BackBtn />
                <h2>Lender</h2>
              </div>
              <Filter onDateChange={onDateChange} />
            </div>

            <div className='dashboard_add_new_btn'>
              <Link to='/add-lender' className=''>
                Add New
              </Link>
            </div>
          </div>

          <section className='tabs_main_section commaon_tabs_main_section'>
            <div className='table_heading_search_box_div'>
              <h1 className='list_heading'>List of Lenders</h1>
              <div className="table_heading_search_box_div_inner">
                <img src={search} alt="search" className="img-fluid" />
                <input
                  type="search"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>



            <ToastContainer />

            <section className='table_main_section lender_table'>
              <div className='table-responsive'>
                <table className='table  commaon_table '>
                  <thead className='table_head'>
                    <tr>
                      <th scope='col'>Lender ID</th>
                      <th scope='col'>Lender Name</th>
                      <th scope='col'>Email</th>
                      <th scope='col'>Cases Logged</th>
                      <th scope='col'>Cases Approved</th>
                      <th scope='col'>Cases Pending</th>
                      <th scope='col'>Action</th>
                    </tr>
                  </thead>

                  <tbody className=''>
                    {isLoading && (
                      <div className='pos_abs'>
                        <Loader />
                      </div>
                    )}
                    {filteredData.length === 0 ? (
                      <tr>
                        <td colSpan="7">Data not found</td>
                      </tr>
                    ) : (
                      filteredData.map(currEle => {
                        return (
                          <>
                            <tr key={currEle.lender_id}>
                              <td>{currEle.lender_id}</td>
                              <td className="name">
                                <Link to={`/lenders/lender-profile/${currEle._id}`}>{currEle.name}</Link>
                              </td>
                              <td className="email">{currEle.email}</td>
                              <td>{currEle.case_logged}</td>
                              <td>{currEle.case_approved}</td>
                              <td>{currEle.case_pending}</td>
                              <td>
                                <Link to={`/lender-profile/${currEle._id}`} className="table_edit_delete_btn">
                                  Edit
                                </Link>
                                <Link onClick={() => openDeleteModal(currEle._id)} className="table_edit_delete_btn">
                                  Delete
                                </Link>
                              </td>
                            </tr>
                            </>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </section>
          </section>
        </section>
      </AdminDashboardMain>

      {/*delete modal */}

      <Modal show={show} onHide={handleClose} className='modal_main'>
        <Modal.Body className='moadal_body'>
          <p> Are you sure you want to delete?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className='table_edit_delete_btn' onClick={handleClose}>
            Close
          </button>
          <button
            className='table_edit_delete_btn'
            onClick={() => {
              DeleteLender(selectedLenderId)
              handleClose()
            }}
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Lenders
