import React, { useEffect, useState } from 'react'
import AdminDashboardMain from './AdminDashboardMain'
import upload from '../assets/imgs/icosn/upload.svg'
import { Link, useNavigate } from 'react-router-dom'
import { BiEdit } from 'react-icons/bi'
import BackBtn from './BackBtn'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';


const AddNewLender = () => {
  const userToken = localStorage.getItem('borroweruserid')

  // file upload
  const [ChannelPartnership, setChannelPartnership] = useState(null)
  const handleFileChange = (event, setterFunction) => {
    const selectedFile = event.target.files[0]
    setterFunction(selectedFile)
  }
  const navigate = useNavigate()

  // profile upload
  const [selectedImage, setSelectedImage] = useState(null)
  const [previewImage, setPreviewImage] = useState(null)
  const[generatedUrl,setGeneratedUrl]=useState(null)
  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleImageChange = event => {
    const file = event.target.files[0]
    generateImgUrl(file)
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setSelectedImage(file)
        setPreviewImage(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }

  // validation error hooks
  const [mobileError, setMobileError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [errorMessage, setErrorMessage] = useState('')
  const [nameError, setNameError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const [name, setname] = useState('')
  const [contact_person_name, setcontact_person_name] = useState('')
  const [mobile, setmobile] = useState('')
  const [email, setEmail] = useState('')
  const [password, setpassword] = useState('')
  const [commission, setcommission] = useState('')
  const [channel_partnership_agrement, setchannel_partnership_agrement] =
    useState('')
    // const [userType, setuserType] = useState(2);

    const [fileUrl, setFileUrl] = useState(null)
  const [previousState, setPreviousState] = useState([])

  let fileObj = {}


  const [avatar, setavatar] = useState(generatedUrl)

  let items = {
    email,
    contact_person_name,
    password,
    name,
    mobile,
    commission,
    avatar,
    channel_partnership_agrement,
    // userType
  }



  // ===========CreateNewLender API===========
  const CreateNewLender = e => {
    e.preventDefault()

    // if (nameError || emailError || mobileError || passwordError ) {
    if (emailError || mobileError || passwordError) {
      toast.error('Please fix the validation errors before submitting.', {
        autoClose: 3000
      });
      return;
    }

    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')

    var raw = JSON.stringify(items)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    }

    fetch('https://bizfinn.co.in/registerLenders', requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          toast.success(result.msg, {
            autoClose: 2000
          })
          setTimeout(() => {
            navigate('/lenders')
          }, 2000)
        } else {
          if (result.errors) {
            setErrorMessage(result.errors.msg)
          } else {
            setErrorMessage(result.msg)
          }
        }
        console.log(result)
      })
      .catch(error => console.log('error', error))
  }


  // mobile number validations
  const validateMobile = () => {
    const mobilePattern = /^\d{10}$/;

    if (!mobile.match(mobilePattern)) {
      setMobileError('Please enter a valid 10-digit mobile number.');
    } else {
      setMobileError('');
    }
  };

  // Email ID validations
  const validateEmail = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email.match(emailPattern)) {
      setEmailError('Please enter a valid email address.');
    } else {
      setEmailError('');
    }
  };

  // lender name validations
  // const validateName = () => {
  //   const namePattern = /^[A-Za-z]{3,}$/;

  //   if (!name.match(namePattern)) {
  //     setNameError('Please enter a valid lender name with at least 3 alphabetic characters.');
  //   } else {
  //     setNameError('');
  //   }
  // };

  // password
  const validatePassword = () => {
    const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

    if (!password.match(passwordPattern)) {
      setPasswordError('Please enter a strong password with at least 8 characters, including letters, numbers, and special characters.');
    } else {
      setPasswordError('');
    }
  };



  // =======================================
  // ==========File upload API===========
  // =======================================
  const uploadFile = (file, type) => {
    if (!file) {
      console.error('No file selected');
      return;
    }

    var formdata = new FormData();
    formdata.append("userId", userToken);
    formdata.append("file", file);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch("https://bizfinn.co.in/uploadfile", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setFileUrl(result?.fileUrl)
        fileObj[type] = result?.fileUrl;
        setPreviousState([...previousState, fileObj])
        // console.log(fileObj)


      })
      .catch(error => console.log('error', error));
  };


    // ======== Profile Image upload API================

  const generateImgUrl=(image)=>{
    var formdata = new FormData();
    formdata.append("file", image);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch("https://bizfinn.co.in/uploadfile", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if(result){
          setGeneratedUrl(result&&result?.fileUrl)
        }
      })
      .catch(error => console.log('error', error));
  }

  
  return (
    <>
      <AdminDashboardMain>
        <section className='side_content_main_box'>
          <div className='page_heading_div'>
            <div className='back_btn_filter_main'>
              <div className='back_btn_filter_inner'>
                <BackBtn />
                <h2>Add New Lender</h2>
              </div>
            </div>
          </div>
          <ToastContainer />

          <section className='add_new_lender_main_section'>
            <form onSubmit={CreateNewLender}>
              <div className='row'>
                <div className='col-xl-5 col-lg-8 col-md-8 col-sm-12 order-2 order-md-1'>
                  <div className='custom_file_upload_main'>
                    <label
                      htmlFor='ChannelPartnership'
                      className='file-input-button'
                    >
                      {ChannelPartnership ? (
                        <div className='file_upload_flex'>
                          <span> {ChannelPartnership.name}</span>
                          <img
                            src={upload}
                            alt='upload'
                            className='img-fluid'
                          />
                        </div>
                      ) : (
                        <div className='file_upload_flex'>
                          <span>Upload Channel Partnership Agrement</span>
                          <img
                            src={upload}
                            alt='upload'
                            className='img-fluid'
                          />
                        </div>
                      )}
                    </label>
                    <input
                      type='file'
                      id='ChannelPartnership'
                      style={{ display: 'none' }}
                      onChange={e => handleFileChange(e, setChannelPartnership)}
                    />
                  </div>

                  <div className=''>
                    <input
                      type='text'
                      className='form_input_box'
                      placeholder='Lender Name'
                      autoCorrect='off'
                      autoComplete='off'
                      value={name}
                      onChange={e => setname(e.target.value)}
                    // onBlur={validateName}
                    />

                    <div className='error_msg same_line_error_msg'>
                      {nameError && <div className='error'>{nameError}</div>}
                    </div>

                  </div>
                  <div className=''>
                    <input
                      type='text'
                      className='form_input_box'
                      placeholder='Contact Person name'
                      autoCorrect='off'
                      autoComplete='off'
                      value={contact_person_name}
                      onChange={e => setcontact_person_name(e.target.value)}
                    />
                  </div>
                  <div className=''>
                    <input
                      type='tel'
                      className='form_input_box'
                      placeholder='Mobile'
                      autoCorrect='off'
                      autoComplete='off'
                      value={mobile}
                      onChange={e => setmobile(e.target.value)}
                      onBlur={validateMobile}
                    />
                    <div className='error_msg same_line_error_msg'>
                      {mobileError && <div className='error'>{mobileError}</div>}
                    </div>

                  </div>
                  <div className=''>
                    <input
                      type='email'
                      className='form_input_box'
                      placeholder='Email Id'
                      autoCorrect='off'
                      autoComplete='off'
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      onBlur={validateEmail}
                    />
                    <div className='error_msg same_line_error_msg'>
                      {emailError && <div className='error'>{emailError}</div>}
                    </div>

                  </div>
                  <div className='pass_show_hide_btn_pos_rel'>
                    <input
                      type={passwordVisible ? 'text' : 'password'}
                      className='form_input_box'
                      placeholder='Password'
                      autoCorrect='off'
                      autoComplete='off'
                      value={password}
                      onChange={e => setpassword(e.target.value)}
                      onBlur={validatePassword}
                    />
                    <div onClick={togglePasswordVisibility} className='pass_show_hide_btn' style={{ marginTop: "-10px" }}>
                          {passwordVisible ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                    </div> 
                  </div>
                  <div className='error_msg same_line_error_msg'>
                      {passwordError && <div className='error'>{passwordError}</div>}
                    </div>
                  <div className=''>
                    <input
                      type='number'
                      className='form_input_box'
                      placeholder='Commission in Percentage(%)'
                      autoCorrect='off'
                      autoComplete='off'
                      value={commission}
                      onChange={e => setcommission(e.target.value)}
                      required
                    />
                  </div>
                  {/* =============== Error msg=============== */}
                  <div className='error_msg'>
                    {errorMessage && (
                      <div className='error'>
                        {' '}
                        <p> {errorMessage} </p>
                      </div>
                    )}
                  </div>
                  <div className='form_both_btn_div_main'>
                    <button type='submit' className='create_new'>
                      Create New
                    </button>

                    {/* <Link to='#' className='create_new Reset_Password_btn'>
                      Reset Password
                    </Link> */}
                  </div>
                </div>
                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 order-1 order-md-2'>
                  <div className=' custom_profile_upload_btn'>
                    <label htmlFor='profile' className='file_input_button'>
                      {selectedImage ? (
                        <div className='file_upload_flex'>
                          <BiEdit className='profile_edit_icon' />
                        </div>
                      ) : (
                        <div className='file_upload_flex'>
                          <img
                            src={upload}
                            alt='upload'
                            className='img-fluid'
                          />
                          <span>Upload Image</span>
                        </div>
                      )}
                    </label>
                    <input
                      type='file'
                      id='profile'
                      style={{ display: 'none' }}
                      onChange={handleImageChange}
                    />
                    {previewImage && (
                      <img
                        className='profile_img_preview'
                        src={previewImage}
                        alt='Preview'
                      />
                    )}
                  </div>
                </div>
              </div>
            </form>
          </section>
        </section>
      </AdminDashboardMain>
    </>
  )
}

export default AddNewLender
