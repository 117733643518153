import React, { useEffect, useState } from 'react'
import AdminDashboardMain from './AdminDashboardMain'
import { Link } from 'react-router-dom'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Filter from './Filter'
import BackBtn from './BackBtn'
// import loader from '../assets/imgs/loader.gif'
import { ToastContainer, toast } from 'react-toastify'
import dayjs from 'dayjs'
import Loader from './Loader'

const Query = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [queryList, setQueryList] = useState([])

  // list of query
  const listofQuery = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    setIsLoading(true)
    fetch("https://bizfinn.co.in/getQuery", requestOptions)
      .then(response => response.json())
      .then(result => {
        setQueryList(result)
        setIsLoading(false)
      })
      .catch(error => {
        console.log('error', error)
        setIsLoading(false)
      });
  }
  useEffect(() => {
    listofQuery()
    console.log('queryList ', queryList)
  }, [])


  const onDateChange=(fromDate , toDate)=>{
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    fetch(`https://bizfinn.co.in/getQuery?fromDate=${fromDate}&toDate=${toDate}`, requestOptions)
    .then(response => response.json())
    .then(result => {
      setQueryList(result)
      setIsLoading(false)
    })
    .catch(error => {
      console.log('error', error)
      setIsLoading(false)
    });
  }




  return (
    <>
      <AdminDashboardMain>
        <section className="side_content_main_box">
          <div className="page_heading_div">
            <div className="back_btn_filter_main">
              <div className="back_btn_filter_inner">
                <BackBtn />
                <h2>Query</h2>
              </div>
              <Filter onDateChange={onDateChange}/>
            </div>
            <div className="dashboard_add_new_btn">
              <Link to="/add-query" className="">
                Create New
              </Link>
            </div>
          </div>

          <section className="tabs_main_section commaon_tabs_main_section">
            <Tabs
              defaultActiveKey="PendingList"
              id="uncontrolled-tab-example"
              className="commaon_tabs_main"
            >
              <Tab eventKey="PendingList" title="List of Pending">
                <section className="table_main_section">
                  <div className="table-responsive">
                    <table className="table   commaon_table">
                      <thead className="table_head">
                        <tr>
                          <th>Case No</th>
                          <th>Date</th>
                          <th>Lender</th>
                          <th>Borrower</th>
                          <th>Status</th>
                          <th>Lender Complaint</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && (
                          <div className='pos_abs'>
                            <Loader />
                          </div>
                        )}
                        {queryList?.data ? (
                          queryList.data
                            .filter((currEle) => currEle?.status === 0)
                            .slice()
                            .reverse()
                            .map((currEle) => {
                              return (
                                <tr key={currEle?._id}>
                                  <td className="name">
                                    <Link to={`/query/query-details/${currEle?._id}`}>{currEle?.case_no}</Link>
                                  </td>
                                  <td>{dayjs(currEle?.createdAt).format('DD-MM-YYYY')}</td>
                                  <td className="name"> <Link to={`/lenders/lender-profile/${currEle.lenderId}`}>{currEle?.lender_name}</Link> </td>
                                  <td className="name">
                                    <Link to={`/borrower/borrower-profile/${currEle?.borrower}`}> {currEle?.borrowerName}</Link>
                                  </td>
                                  <td >{currEle?.status === 0 ? <span className="table_pending_btn">Pending</span> :
                                    currEle?.status === 1 ? <span className="table_pending_btn table_approved_btn">Completed</span> : ""}</td>
                                  <td>{currEle?.comment}</td>
                                </tr>
                              );
                            })
                        ) : (
                          <tr>
                            <td colSpan="6">No Data Found</td>
                          </tr>
                        )}

                      </tbody>
                    </table>
                  </div>
                </section>
              </Tab>
              <Tab eventKey="CompletedList" title="List of Completed">
                <section className="table_main_section">
                  <div className="table-responsive">
                    <table className="table   commaon_table">
                      <thead className="table_head">
                        <tr>
                          <th scope="col">Case No</th>
                          <th scope="col">Date</th>
                          <th scope="col">Lender</th>
                          <th scope="col">Borrower</th>
                          <th scope="col">Status</th>
                          <th scope="col">Lender Query</th>
                        </tr>
                      </thead>

                      <tbody>
                        {isLoading && (
                          <div className='pos_abs'>
                            <Loader />
                          </div>
                        )}
                        {queryList?.data ? (
                          queryList.data
                            .filter((currEle) => currEle?.status === 1)
                            .slice()
                            .reverse()
                            .map((currEle) => {
                              return (
                                <tr key={currEle?._id}>
                                  <td className="name">
                                    <Link to={`/query-details/${currEle?._id}`}>{currEle?.case_no}</Link>
                                    {/* <Link to="#">{currEle?.case_no}</Link> */}
                                  </td>
                                  <td>{dayjs(currEle?.createdAt).format('DD-MM-YYYY')}</td>
                                  <td className="name"> <Link to={`/lenders/lender-profile/${currEle.lenderId}`}>{currEle?.lender_name}</Link> </td>
                                  <td className="name">
                                    <Link to={`/borrower/borrower-profile/${currEle?.borrower}`}> {currEle?.borrowerName}</Link>
                                  </td>
                                  <td >{currEle?.status === 0 ? <span className="table_pending_btn">Pending</span> :
                                    currEle?.status === 1 ? <span className="table_pending_btn table_approved_btn">Completed</span> : ""}</td>
                                  <td>{currEle?.comment}</td>
                                </tr>
                              );
                            })
                        ) : (
                          <tr>
                            <td colSpan="6">No Data Found</td>
                          </tr>
                        )}
                      </tbody>

                    </table>
                  </div>
                </section>
              </Tab>
            </Tabs>
          </section>
        </section>
      </AdminDashboardMain>
    </>
  )
}

export default Query
