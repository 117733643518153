import React, { useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import cross from '../assets/imgs/icosn/cross.png'
import right from '../assets/imgs/icosn/right.png'
import dayjs from 'dayjs'
import { ToastContainer, toast } from 'react-toastify'
import upload from '../assets/imgs/icosn/download.png'
import BackBtn from '../admin/BackBtn'
import LenderDashboardMain from './LenderDashboardMain'
import axios from 'axios';
import { formatNumberWithPostfix } from '../utils/ConvertNumbers';

const CaseDetails = () => {

  const { _id } = useParams()
  const userId = localStorage.getItem('lenderuserid')
  const [singleCase, setSingleCase] = useState([])
  let singleCaseLenderList = singleCase?.lenders;
  const navigate = useNavigate();
  const [comments, setcomments] = useState('')
  const [Apcomments, setApcomments] = useState('')
  const [approvedAmount, setapprovedAmount] = useState()

  const [SingleBorrower, setSingleBorrower] = useState([])

  const [zipFilesType, setzipFilesType] = useState('Financial_Details')

  const [GSTLast, setGSTLast] = useState(null)
  const [BankStatement, setBankStatement] = useState(null)
  const [UploadDoc, setUploadDoc] = useState(null)

  const [lenderObjectFromCase, setlenderObjectFromCase] = useState({})

  const handleFileChange = (event, setterFunction) => {
    const selectedFile = event.target.files[0]
    setterFunction(selectedFile)
  }


  // ============MIS================
  const [MISCheckActive, setMISCheckActive] = useState(false);
  const [MIScrossActive, setMIScrossActive] = useState(false);
  const handleClickMISCheck = () => {
    setMISCheckActive(true);
    setMIScrossActive(false);
  };
  const handleClickMISCross = () => {
    setMISCheckActive(false);
    setMIScrossActive(true);
  };


  // ============Debet================
  const [DebetCheckActive, setDebetCheckActive] = useState(false);
  const [DebetcrossActive, setDebetcrossActive] = useState(false);

  const handleClickDebetCheck = () => {
    setDebetCheckActive(true);
    setDebetcrossActive(false);
  };

  const handleClickDebetCross = () => {
    setDebetCheckActive(false);
    setDebetcrossActive(true);
  };


  // ============ITR================
  const [ITRCheckActive, setITRCheckActive] = useState(false);
  const [ITRcrossActive, setITRcrossActive] = useState(false);

  const handleClickITRCheck = () => {
    setITRCheckActive(true);
    setITRcrossActive(false);
  };

  const handleClickITRCross = () => {
    setITRCheckActive(false);
    setITRcrossActive(true);
  };


  // ============Provisional================
  const [ProvisionalCheckActive, setProvisionalCheckActive] = useState(false);
  const [ProvisionalcrossActive, setProvisionalcrossActive] = useState(false);

  const handleClickProvisionalCheck = () => {
    setProvisionalCheckActive(true);
    setProvisionalcrossActive(false);
  };

  const handleClickProvisionalCross = () => {
    setProvisionalCheckActive(false);
    setProvisionalcrossActive(true);
  };


  // ============Bank Statement================
  const [BankCheckActive, setBankCheckActive] = useState(false);
  const [BankcrossActive, setBankcrossActive] = useState(false);

  const handleClickBankCheck = () => {
    setBankCheckActive(true);
    setBankcrossActive(false);
  };

  const handleClickBankCross = () => {
    setBankCheckActive(false);
    setBankcrossActive(true);
  };

  // ============GST Filling================
  const [GSTCheckActive, setGSTCheckActive] = useState(false);
  const [GSTcrossActive, setGSTcrossActive] = useState(false);

  const handleClickGSTCheck = () => {
    setGSTCheckActive(true);
    setGSTcrossActive(false);
  };

  const handleClickGSTCross = () => {
    setGSTCheckActive(false);
    setGSTcrossActive(true);
  };

  // ============Audited================
  const [AuditedCheckActive, setAuditedCheckActive] = useState(false);
  const [AuditedcrossActive, setAuditedcrossActive] = useState(false);

  const handleClickAuditedCheck = () => {
    setAuditedCheckActive(true);
    setAuditedcrossActive(false);
  };

  const handleClickAuditedCross = () => {
    setAuditedCheckActive(false);
    setAuditedcrossActive(true);
  };


  // =======================================
  // ========Get Single case============
  // =======================================
  const GetSingleCase = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/getSingleCase/${_id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result)
        setSingleCase(result?.data)
        const [data] = result.data.lenders.filter((obj) => obj.lenderId == userId)
        console.log(data);
        setlenderObjectFromCase(data);
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    GetSingleCase()
    console.log("setSingleCase", singleCase?.borrower)
  }, [_id])



  // =======================================
  // ========Get Single Borrower============
  // =======================================

  const GetSingleBorrower = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "borrowerId": singleCase?.borrower
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://bizfinn.co.in/getSingleBorrower", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setSingleBorrower(result?.data)
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    GetSingleBorrower()
  }, [singleCase?.borrower])

  const [selectedOption, setSelectedOption] = useState("Approved");
  const [selectedReason, setSelectedReason] = useState("");
  const [ReasonerrorMessage, setReasonErrorMessage] = useState("");
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setSelectedReason(""); // Reset selected reason when changing options
    setReasonErrorMessage("");
  };

  const handleReasonClick = (reason) => {
    setSelectedReason(reason);
    setcomments(reason);
  };
  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (selectedOption === "Declined" && !selectedReason) {
      setReasonErrorMessage("Choose a reason for decline the case.");
    } else {
      setReasonErrorMessage("");
      // Perform your submit logic here
    }
  };




  // =======================================
  // ========Case Approved status============
  // =======================================

  const caseApprovedStatus = () => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "status": 3
    });

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/caseStatus/${_id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result)
        if (result.msg) {
          // toast.success(result.msg, {
          //   autoClose: 2000
          // })
          setTimeout(() => {
            navigate("/lender_cases");
          }, 2000)
        }
      })
      .catch(error => console.log('error', error));




    // var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");

    // var raw = JSON.stringify({
    //   "approved": 0,
    //   "lenderId": userId,
    //   "lander_approved": 0,
    //   "remark": Apcomments
    // });

    // var requestOptions = {
    //   method: 'PUT',
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: 'follow'
    // };

    // fetch(`https://bizfinn.co.in/lenderCaseStatus/${_id}`, requestOptions)
    //   .then(response => response.json())
    //   .then(result => {console.log(result)
    //     if (result.msg) {
    //             toast.success(result.msg, {
    //               autoClose: 2000
    //             })
    //             setTimeout(() => {
    //               navigate("/lender_cases");
    //             }, 2000)
    //           }

    //   })
    //   .catch(error => console.log('error', error)); 


    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "lenderId": userId,
      "lander_approved": 1,
      "lender_remark": Apcomments,
      "approved_amount": parseInt(approvedAmount) * 100000
      // "lender_remark": "Approved"

    });

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/lenderCaseStatus/${_id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.msg) {
          toast.success(result.msg, {
            autoClose: 2000
          })
          setTimeout(() => {
            navigate("/lender_cases");
          }, 2000)
        }

      })
      .catch(error => console.log('error', error));
  }


  // =======================================
  // ========Case reject API============
  // =======================================

  const CaseRejectStatus = () => {
    console.log('case approved')

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "status": 3
    });

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/caseStatus/${_id}/${userId}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (selectedOption === "Declined" && !selectedReason) {
          setReasonErrorMessage("Choose a reason for decline the case.");
        } else {
          setReasonErrorMessage("");
          if (result.msg) {
            toast.success(result.msg, {
              autoClose: 2000
            })
            setTimeout(() => {
              navigate("/lender_cases");
            }, 2000)
          }
        }


      })
      .catch(error => console.log('error', error));
  }


  // =======================================
  // ========Case Reject on declined button============
  // =======================================
  const CaseReject = () => {
    if (selectedOption === "Declined" && !selectedReason) {
      setReasonErrorMessage("Choose a reason for declining the case.");
    } else {
      setReasonErrorMessage(""); // Clear any previous error message
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "status": 2,
        "lender_remark": comments
      });

      var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`https://bizfinn.co.in/caseStatus/${_id}/${userId}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          if (result.msg) {
            toast.success(result.msg, {
              autoClose: 2000
            })
            setTimeout(() => {
              navigate("/lender_cases");
            }, 2000)
          }
        })
        .catch(error => console.log('error', error));
    }
  }



  // =======================================
  // ========FileApprovedREject API============
  // =======================================

  const BorroweruserId = SingleBorrower?._id


  const FileApprovedREject = (status, Schema, doctype) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "status": status
    });

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/updateCaseDocStatus/${_id}/${Schema}/${doctype}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        GetSingleCase()
      })
      .catch(error => console.log('error', error));
  }

  const handleZipDownload = async () => {

  }

  return (
    <>
      <LenderDashboardMain>
        <div className='full_width_bg_main'>
          <section className='side_content_main_box'>
            <div className='page_heading_div'>
              <div className='back_btn_filter_main'>
                <BackBtn />
                <h2>Cases</h2>
              </div>
            </div>
            <ToastContainer />

            <section className=''>
              <section className='table_main_section'>
                <div className='table-responsive'>
                  <table className='table   commaon_table'>
                    <thead className='table_head'>
                      <tr>
                        <th scope='col'>Case No</th>
                        <th scope='col'>Date</th>
                        <th scope='col'>Borrower Name</th>
                        <th scope="col">Type of Loan</th>
                        <th scope="col">Turnover</th>
                        <th scope="col">Loan Ask</th>
                        <th scope="col">Loan Approve</th>
                        <th scope="col">Status</th>
                        <th scope="col">Select Lender</th>
                        <th scope="col">Lender Remark</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className='name'>
                          {singleCase?.case_no}
                        </td>
                        <td>{dayjs(singleCase?.createdAt).format(
                          ' DD-MM-YYYY'
                        )}</td>
                        <td className='name'>
                          <Link to={`/borrower__profile/${singleCase?.borrower}`}> {singleCase?.borrowerName} </Link>
                          {/* <Link to="#"> {singleCase?.borrowerName} </Link> */}
                        </td>
                        <td>{singleCase?.type_of_loan}</td>
                        <td> {singleCase?.borrowerTurnOver}</td>
                        <td>{formatNumberWithPostfix(singleCase?.requirement)} </td>
                        <td>{formatNumberWithPostfix(lenderObjectFromCase.approved_amount)} </td>

                        <td >{lenderObjectFromCase.lander_approved == 0 && lenderObjectFromCase.approved == 0 ? <span className="table_pending_btn">Pending</span> :
                          lenderObjectFromCase.approved == 1 && lenderObjectFromCase.lander_approved == 1 ? <span className="table_pending_btn table_approved_btn">Approved</span> :
                            lenderObjectFromCase.approved == 3 && lenderObjectFromCase.lander_approved == 1 ? <span className="table_pending_btn">	Rejected</span> :
                              lenderObjectFromCase.approved == 0 && lenderObjectFromCase.lander_approved == 1 ? <span className="table_pending_btn table_progress_btn">	Progress</span> : ""}</td>
                        <td>{singleCase?.lenders?.length}</td>
                        <td>{lenderObjectFromCase.lender_remark}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
            </section>

            <section className='case_detail_content_main_section'>
              <div className='row'>
                <div className="col-xl-7 col-lg-12 col-md-12 col-sm-12">
                  <div>
                    <div className="case_query_reject_btn_main_flex">
                      <div>
                        <div className="case_detail_single_detail">
                          <h2>You are looking for *</h2>
                          <p>{singleCase?.type_of_loan}</p>
                        </div>
                        <div className="case_detail_single_detail">
                          <h2>How much loan you need (in INR)?</h2>
                          <p>{singleCase?.requirement}</p>
                        </div>

                        <div className="case_detail_single_detail">
                          <h2>The Password for Locked Documents</h2>
                          <p>{singleCase?.doc_passwords}</p>
                        </div>

                      </div>

                      {/* <div className="case_query_reject_btn">
                        <Link to="/add-query">Query</Link>
                        <Link >Reject</Link>
                        <Link onClick={CaseRejectStatus}>Reject</Link>

                      </div> */}

                      <div class="case_query_reject_btn Raise_Query_btn_gap">
                        {singleCase?.status == 2 || lenderObjectFromCase.approved == 3 || singleCase?.status == 1 ?
                          ""
                          : <Link to={`/lender_raise_query/${singleCase?._id}`} class="Raise_Query_btn">Raise Query</Link>}
                      </div>

                    </div>

                    {
                      (singleCase.status == 0 || singleCase.status == 3) && lenderObjectFromCase.approved != 3 && lenderObjectFromCase.approved != 1 ? (
                        <div className="Loan_Approved_Status_main">
                          <div className="">
                            <p>Loan Approved Status: </p>
                            <div className="Loan_Approved_Status">
                              <div className="loan_status_label">
                                <input
                                  type="radio"
                                  id="Approved"
                                  name="loan-status"
                                  value="Approved"
                                  checked={selectedOption === "Approved"}
                                  onChange={handleOptionChange}
                                />
                                <label for="Approved">Approved</label>
                              </div>
                              <div className="loan_status_label">
                                <input
                                  type="radio"
                                  id="Declined"
                                  name="loan-status"
                                  value="Declined"
                                  checked={selectedOption === "Declined"}
                                  onChange={handleOptionChange}
                                />
                                <label for="Declined">Declined</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : ('')
                    }



                    {/*========== case cencel reasons=========== */}
                    {selectedOption === "Declined" && (
                      <>
                        <div className="rejection_reason_main">
                          <button
                            onClick={() => handleReasonClick("Incomplete data")}
                            className={selectedReason === "Incomplete data" ? "active" : ""}
                          >
                            Incomplete data
                          </button>
                          <button
                            onClick={() => handleReasonClick("Low CIBIL")}
                            className={selectedReason === "Low CIBIL" ? "active" : ""}
                          >
                            Low CIBIL
                          </button>
                          <button
                            onClick={() => handleReasonClick("High Leverage")}
                            className={selectedReason === "High Leverage" ? "active" : ""}
                          >
                            High Leverage
                          </button>
                          <button
                            onClick={() => handleReasonClick("Low Runway")}
                            className={selectedReason === "Low Runway" ? "active" : ""}
                          >
                            Low Runway
                          </button>
                        </div>
                        <div className="leder_description width_90">
                          <textarea
                            rows="3"
                            cols="50"
                            className="form_input_box Enter_Comment"
                            placeholder="Enter other Comment"
                            value={comments}
                            onChange={e => setcomments(e.target.value)}
                          ></textarea>
                        </div>
                      </>
                    )}

                    {ReasonerrorMessage && (
                      <div className='mb-3 error_msg same_line_error_msg'>
                        {ReasonerrorMessage}
                      </div>
                    )}


                    {singleCase?.status == 2 || lenderObjectFromCase.approved == 3 || lenderObjectFromCase.approved == 1 ? "" : (
                      <>
                        {selectedOption != "Declined" && (
                          <>
                            <div className="leder_description width_70">
                              <input
                                className="form_input_box Enter_Comment"
                                placeholder="Enter Approved Amount (INR Lakhs)"
                                required
                                value={approvedAmount}
                                onChange={e => setapprovedAmount(e.target.value)}
                              ></input>
                            </div>


                            <div className="leder_description width_70">
                              <textarea
                                rows="3"
                                cols="50"
                                className="form_input_box Enter_Comment"
                                placeholder="Enter Comment"
                                value={Apcomments}
                                onChange={e => setApcomments(e.target.value)}
                              ></textarea>
                            </div>
                          </>
                        )}
                      </>
                    )}





                    {/* <div className="loan_approve_message_btn loan_approve_message_btn_mobile">
                      <div className="login_register_btn_div">
                        <Link
                          className="blue_btn login_register_btn"
                        
                        >
                          Approve
                        </Link>
                        <Link
                          to="/chat"

                          className="login_register_btn register_btn simple_btn_hover"
                        >
                          Message
                        </Link>
                      </div>
                    </div> */}





                    <div className="loan_approve_message_btn ">
                      <div className="login_register_btn_div">

                        {/* <div className="submit_button_main">
                          {selectedOption === "Approved" ? (
                            <button className='blue_btn login_register_btn' onClick={caseApprovedStatus}> Approved</button>
                          ) : (
                            <button className='blue_btn login_register_btn' style={{ background: "#f76157" }} onClick={CaseRejectStatus}> Declined</button>
                          )}
                        </div> */}

                        {singleCase?.status == 2 || lenderObjectFromCase.approved == 3 || lenderObjectFromCase.approved == 1 ? "" : (
                          <div className="submit_button_main">
                            {selectedOption === "Approved" ? (
                              <button className='blue_btn login_register_btn' onClick={caseApprovedStatus}> Send</button>
                            ) : (
                              <button className='blue_btn login_register_btn' style={{ background: "#f76157" }} onClick={CaseReject}> Declined</button>
                            )}
                          </div>
                        )}

                      </div>
                    </div>





                  </div>
                </div>
                <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 profile_details_main_section">
                  <div class="download_all_dropdown"><label for="dropdown">Select an option for download all files:</label>
                    <select value={zipFilesType} onChange={event => {
                      setzipFilesType(event.target.value)
                    }} id="dropdown">
                      <option value="">Select Document Type</option>
                      <option value="Financial_Details">Financial Details</option>
                      <option value="KYC_Details ">KYC Details </option>
                      <option value="Business_Details">Business Details</option>
                    </select>
                  </div>

                  <button className='btn btn-success'>
                    <a style={{textDecoration:"none" , color:"white"}} href={`https://bizfinn.co.in/download-zip/${_id}`} download>
                      <span className='mx-2'>
                        Download Zip
                      </span>
                      <img
                        src={upload}
                        alt="upload"
                        className="img-fluid"
                      />
                    </a>

                  </button>

                  <h3>
                    Financial Details <span> (Upload when month change)</span>
                  </h3>
                  <div>
                    <div className="finanicial_details_single">
                      <div className="upload-btn-wrapper">
                        <a href={singleCase?.financial_details?.financial_stat?.url} download="file_name.extension" target='_blank'>
                          <button className="file_upload_btn form_input_box">
                            <span>
                              Download Audited Financial statements for last 3 years
                            </span>
                            <img
                              src={upload}
                              alt="upload"
                              className="img-fluid"
                            />
                          </button>
                        </a>
                        {/* <input type="file" className="form_input_box" readOnly /> */}
                      </div>

                    </div>

                    <div className="finanicial_details_single">
                      <div className="upload-btn-wrapper">
                        <a href={singleCase?.financial_details?.gst_filling?.url} download="file_name.extension" target='_blank'>
                          <button className="file_upload_btn form_input_box">
                            <span> Download GST Filing for last 12 months</span>
                            <img
                              src={upload}
                              alt="upload"
                              className="img-fluid"
                            />
                          </button>
                        </a>
                        {/* <input type="file" className="form_input_box" readOnly /> */}
                      </div>


                    </div>

                    <div className="finanicial_details_single">
                      <div className="upload-btn-wrapper">
                        <a href={singleCase?.financial_details?.bank_statment_24?.url} download="file_name.extension" target='_blank'>
                          <button className="file_upload_btn form_input_box">
                            <span> Download Bank Statement of last 12 months</span>
                            <img
                              src={upload}
                              alt="upload"
                              className="img-fluid"
                            />
                          </button>
                        </a>
                        {/* <input type="file" className="form_input_box"  /> */}
                      </div>

                    </div>

                    <div className="finanicial_details_single">
                      <div className="upload-btn-wrapper">
                        <a href={singleCase?.financial_details?.provisional_sheet?.url} download="file_name.extension" target='_blank'>
                          <button className="file_upload_btn form_input_box">
                            <span>
                              Download provisional balance sheet for the current
                              year
                            </span>
                            <img
                              src={upload}
                              alt="upload"
                              className="img-fluid"
                            />
                          </button>
                        </a>
                        {/* <input type="file" className="form_input_box" /> */}
                      </div>

                    </div>

                    <div className="finanicial_details_single">
                      <div className="upload-btn-wrapper">
                        <a href={singleCase?.financial_details?.itr_acknowledgement?.url} download="file_name.extension" target='_blank'>
                          <button className="file_upload_btn form_input_box">
                            <span>
                              Download ITR acknowledgement form for last 2 years
                            </span>
                            <img
                              src={upload}
                              alt="upload"
                              className="img-fluid"
                            />
                          </button>
                        </a>
                        {/* <input type="file" className="form_input_box" /> */}
                      </div>

                    </div>

                    <div className="finanicial_details_single">
                      <div className="upload-btn-wrapper">
                        <a href={singleCase?.financial_details?.debt_service?.url} download="file_name.extension" target='_blank'>
                          <button className="file_upload_btn form_input_box">
                            <span>
                              Download Debt serviced during the current FY
                              (Principal + interest repayment during the year)
                            </span>
                            <img
                              src={upload}
                              alt="upload"
                              className="img-fluid"
                            />
                          </button>
                        </a>
                        {/* <input type="file" className="form_input_box" /> */}
                      </div>

                    </div>

                    <div className="finanicial_details_single">
                      <div className="upload-btn-wrapper">
                        <a href={singleCase?.financial_details?.mis_additional?.url} download="file_name.extension" target='_blank'>
                          <button className="file_upload_btn form_input_box">
                            <span>Download MIS</span>
                            <img
                              src={upload}
                              alt="upload"
                              className="img-fluid"
                            />
                          </button>
                        </a>
                        {/* <input type="file" className="form_input_box" /> */}
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>
      </LenderDashboardMain>
    </>
  )
}

export default CaseDetails
