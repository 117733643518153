import React from 'react'
import Login from './admin/login/Login'

const Main = () => {
  return (
    <>
      <Login />
    </>
  )
}

export default Main
