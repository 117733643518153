import React, { useEffect, useState } from 'react'
import BorrowerDashboardMain from './BorrowerDashboardMain'
import { Link, useParams, useNavigate } from 'react-router-dom'
import cross from '../assets/imgs/icosn/cross.svg'
import upload from '../assets/imgs/icosn/upload.svg'
import download from '../assets/imgs/icosn/download.png'
import right from '../assets/imgs/icosn/right.png'
import dayjs from 'dayjs'
import { ToastContainer, toast } from 'react-toastify'


const QueryDetails = () => {
  const navigate = useNavigate()
  const { _id } = useParams()
  const [singleQuery, setSingleQuery] = useState('')
  const [singleCase, setSingleCase] = useState([])
  const [isSendTrue, setisSendTrue] = useState(true)

  const [fileUrl, setFileUrl] = useState(null)
  const [financial_stat, setfinancial_stat] = useState(null)
  const [GSTLast, setGSTLast] = useState(null)
  const [BankStatement, setBankStatement] = useState(null)
  const [provisionalbalance, setprovisionalbalance] = useState(null)
  const [ITR, setITR] = useState(null)
  const [Debtserviced, setDebtserviced] = useState(null)
  const [MIS, setMIS] = useState(null)

  const [borrowerData, setBorrowerData] = useState([])
  const [previousState, setPreviousState] = useState([])
  let fileObj = {}
  const [fileName, setFileName] = useState()
  const [comment, setcomment] = useState('')
  const [kycPayloadFanancial, setKycPayloadFanancial] = useState({})
  const userToken = localStorage.getItem('userid')

  const AddRemark = () => {
    console.log("remark added by Lender")
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    if (!!!comment) return;

    var raw = JSON.stringify({
      "commenterId": userToken,
      "remark": comment,
      "type": "Borrower"
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/query/${singleQuery?._id}/comments`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)

        setTimeout(() => {
          window.location.reload();
        }, 100)
      })
      .catch(error => console.log('error', error));
  }

  const [extraDocsUpload, setextraDocsUpload] = useState(null)

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]
    var formdata = new FormData();
    formdata.append("file", selectedFile);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch("https://bizfinn.co.in/uploadfile", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        const fileUrl = result?.fileUrl;
        setextraDocsUpload(fileUrl)
      })
      .catch(error => console.log('error', error));
  }

  const handleFileUpload = (e, f, type) => {
    const file = e.target.files[0];
    setFileName(file)
    uploadFile(file, type);
  }


  useEffect(() => {
    previousState && previousState.map((val) => {
      let key = Object.keys(val)
      key.map((keys) => {
        console.log(keys, val[keys])
        setKycPayloadFanancial({ ...kycPayloadFanancial, [keys]: val[keys] })
      })
    })
  }, [fileUrl])


  // ====================================
  // ========Get Single Query============
  // ====================================

  const GetSingleQuery = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/getSingleQuery/${_id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setSingleQuery(result?.data)
      })
      .catch(error => console.log('error', error));
  }

  // ====================================
  // ========Get Single Case============
  // ====================================
  const GetSingleCase = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/getSingleCase/${singleQuery?.case}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setSingleCase(result?.data)
        console.log("singleCase", singleCase?.lenders)
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    GetSingleQuery();
  }, [_id]);

  useEffect(() => {
    GetSingleCase(singleQuery?.case);
  }, [singleQuery]);



  // =======================================
  // ==========File upload API===========
  // =======================================
  const uploadFile = (file, type) => {
    if (!file) {
      console.error('No file selected');
      return;
    }

    var formdata = new FormData();
    formdata.append("userId", singleQuery?.case?.borrower?._id);
    formdata.append("file", file);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch("https://bizfinn.co.in/uploadfile", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setFileUrl(result?.fileUrl)
        fileObj[type] = result?.fileUrl;
        setPreviousState([...previousState, fileObj])
        // console.log(fileObj)


      })
      .catch(error => console.log('error', error));
  };


  // =======================================
  // ==========Financial Details API===========
  // =======================================
  const Financial_Details = () => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(kycPayloadFanancial);

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/updateBorrowerFinancialDetails/${singleQuery?.case?.borrower?._id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result?.message) {
          toast.success(result?.message, {
            autoClose: 2000
          })
        }
        // window.location.reload();
      })
      .catch(error => console.log('error', error));

  }




  // ====================================
  // ========delete Single Query============
  // ====================================
  const DeleteQuery = () => {
    var requestOptions = {
      method: 'DELETE',
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/deleteQuery/${_id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result?.success) {
          toast.success(result?.msg, {
            autoClose: 2000
          })
          setTimeout(() => {
            navigate('/query')
          }, 2000)
        }
      })
      .catch(error => console.log('error', error));
  }


  //Query update

  const UpdateQuery = () => {

    setisSendTrue(false)

    AddRemark();

    if (!!extraDocsUpload) {

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "url": extraDocsUpload
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`https://bizfinn.co.in/query/${_id}/document`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          setTimeout(() => {
            window.location.reload();
          }, 100)
        })
        .catch(error => console.log('error', error));
    }
  }

  // ====================================
  // ======== Query Approved============
  // ====================================
  const QueryApproved = () => {

    AddRemark()

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "status": 1
    });

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    if (!!extraDocsUpload) {

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "url": extraDocsUpload
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`https://bizfinn.co.in/query/${_id}/document`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
        })
        .catch(error => console.log('error', error));
    }

    fetch(`https://bizfinn.co.in/queryStatus/${_id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result) {
          toast.success(result?.msg, {
            autoClose: 2000
          })
          setTimeout(() => {
            navigate('/query')
          }, 2000)
        }
      })
      .catch(error => console.log('error', error));
  }


  // ============MIS================
  const [MISCheckActive, setMISCheckActive] = useState(false);
  const [MIScrossActive, setMIScrossActive] = useState(false);
  const handleClickMISCheck = () => {
    setMISCheckActive(true);
    setMIScrossActive(false);
  };
  const handleClickMISCross = () => {
    setMISCheckActive(false);
    setMIScrossActive(true);
  };


  // ============Debet================
  const [DebetCheckActive, setDebetCheckActive] = useState(false);
  const [DebetcrossActive, setDebetcrossActive] = useState(false);

  const handleClickDebetCheck = () => {
    setDebetCheckActive(true);
    setDebetcrossActive(false);
  };

  const handleClickDebetCross = () => {
    setDebetCheckActive(false);
    setDebetcrossActive(true);
  };


  // ============ITR================
  const [ITRCheckActive, setITRCheckActive] = useState(false);
  const [ITRcrossActive, setITRcrossActive] = useState(false);

  const handleClickITRCheck = () => {
    setITRCheckActive(true);
    setITRcrossActive(false);
  };

  const handleClickITRCross = () => {
    setITRCheckActive(false);
    setITRcrossActive(true);
  };


  // ============Provisional================
  const [ProvisionalCheckActive, setProvisionalCheckActive] = useState(false);
  const [ProvisionalcrossActive, setProvisionalcrossActive] = useState(false);

  const handleClickProvisionalCheck = () => {
    setProvisionalCheckActive(true);
    setProvisionalcrossActive(false);
  };

  const handleClickProvisionalCross = () => {
    setProvisionalCheckActive(false);
    setProvisionalcrossActive(true);
  };


  // ============Bank Statement================
  const [BankCheckActive, setBankCheckActive] = useState(false);
  const [BankcrossActive, setBankcrossActive] = useState(false);

  const handleClickBankCheck = () => {
    setBankCheckActive(true);
    setBankcrossActive(false);
  };

  const handleClickBankCross = () => {
    setBankCheckActive(false);
    setBankcrossActive(true);
  };

  // ============GST Filling================
  const [GSTCheckActive, setGSTCheckActive] = useState(false);
  const [GSTcrossActive, setGSTcrossActive] = useState(false);

  const handleClickGSTCheck = () => {
    setGSTCheckActive(true);
    setGSTcrossActive(false);
  };

  const handleClickGSTCross = () => {
    setGSTCheckActive(false);
    setGSTcrossActive(true);
  };

  // ============Audited================
  const [AuditedCheckActive, setAuditedCheckActive] = useState(false);
  const [AuditedcrossActive, setAuditedcrossActive] = useState(false);

  const handleClickAuditedCheck = () => {
    setAuditedCheckActive(true);
    setAuditedcrossActive(false);
  };

  const handleClickAuditedCross = () => {
    setAuditedCheckActive(false);
    setAuditedcrossActive(true);
  };

  const handleQueryDocDelete = (query, docId) => {
    const url = `query/${query._id}/document/${docId}`
    var requestOptions = {
      method: 'DELETE',
    };

    fetch(`https://bizfinn.co.in/${url}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        //After deleted successfully
        var requestOptions = {
          method: 'GET',
          redirect: 'follow'
        };

        fetch(`https://bizfinn.co.in/getSingleQuery/${_id}`, requestOptions)
          .then(response => response.json())
          .then(result => {
            setSingleQuery(result?.data)
          })
          .catch(error => console.log('error', error));
      })
      .catch(error => console.log('error', error));

  }


  return (
    <>
      <BorrowerDashboardMain>
        <div className='full_width_bg_main'>
          <section className='side_content_main_box'>
            <div className='page_heading_div'>
              <div className='back_btn_filter_main'>
                <h2>Query</h2>
              </div>
            </div>
            <ToastContainer />

            <section className=''>
              <section className='table_main_section'>
                <div className='table-responsive'>
                  <table className='table   commaon_table'>
                    <thead className='table_head'>
                      <tr>
                        <th scope='col'>Case No</th>
                        <th scope='col'>Date</th>
                        <th scope='col'>Lender</th>
                        <th scope='col'>Product</th>
                        <th scope='col'>Lender Status</th>
                        <th scope='col'>Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <Link to='#'> {singleQuery?.case_no} </Link>
                        </td>
                        <td>{dayjs(singleQuery?.createdAt).format(
                          ' DD-MM-YYYY'
                        )}</td>
                        <td>{singleQuery?.lender_name}</td>
                        <td className='name'>
                          {singleQuery?.borrowerName}
                        </td>
                        <td >{singleQuery?.status === 0 ? <span className="table_pending_btn">Pending</span> :
                          singleQuery?.status === 1 ? <span className=" table_approved_btn">Completed</span> : ""}</td>
                        <td>{singleQuery?.comment}</td>

                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
            </section>

            <section className='case_detail_content_main_section'>
              <div className='row'>
                <div className='col-xl-7 col-lg-12 col-md-12 col-sm-12 '>
                  <div className='two_col_right_gap '>
                    <div className='case_query_reject_btn_main_flex'>
                      <div>
                        <div className='case_detail_single_detail'>
                          <h2>You are is looking for</h2>
                          <p>{singleCase?.type_of_loan}</p>
                        </div>
                        <div className='case_detail_single_detail'>
                          <h2> How much loan you need (in INR)?</h2>
                          <p>{singleCase?.requirement}</p>
                        </div>

                        {/* <div className='case_detail_single_detail'>
                          <h2>Lender Remarks</h2>
                          <p>{singleQuery?.comment}</p>
                        </div> */}
                      </div>

                      {/* <div className='case_query_reject_btn'>
                        <Link to='/query'>Edit</Link>
                        <Link to='/cases'>Cancel</Link>
                      </div> */}
                    </div>

                    <div className='query_details_steps_main'>
                      {singleQuery?.comments?.slice().reverse().map((currEle) => {
                        return (
                          <div className='query_details_steps_single'>
                            <div className='steps_dots_main steps_dot_active'>
                              <span></span>
                            </div>
                            <div className='query_details_steps_inner_main'>
                              <div>
                                <h4>{currEle?.type}</h4>
                                <h5>{currEle?.remark}</h5>
                              </div>
                              <p>{dayjs(currEle?.createdAt).format(
                                ' DD-MM-YYYY'
                              )}</p>
                            </div>
                          </div>
                        )
                      })
                      }
                    </div>

                    {singleQuery.status != 1 ? (
                      <>
                        <div className="finanicial_details_single Upload_Doc_input">
                          <div className="custom_file_upload_main">
                            <label
                              htmlFor="UploadDoc"
                              className="file-input-button"
                            >
                              {!!extraDocsUpload ? (
                                <div className="file_upload_flex">
                                  <span> {extraDocsUpload}</span>
                                  <img
                                    src={upload}
                                    alt="upload"
                                    className="img-fluid"
                                  />
                                </div>
                              ) : (
                                <div className="file_upload_flex">
                                  <span>Upload Doc</span>
                                  <img
                                    src={upload}
                                    alt="upload"
                                    className="img-fluid"
                                  />
                                </div>
                              )}
                            </label>
                            <input
                              type="file"
                              id="UploadDoc"
                              style={{ display: 'none' }}
                              onChange={(e) => handleFileChange(e)}
                            />
                          </div>
                        </div>

                        <div className="case_detail_single_detail mt-5">
                          <h2>Borrower Comment</h2>

                          <div className="finanicial_details_single mt-2">
                            <textarea
                              rows="4"
                              cols="50"
                              className="form_input_box Enter_Comment"
                              placeholder="Enter Comment"
                              value={comment}
                              onChange={e => setcomment(e.target.value)}
                            ></textarea>
                          </div>
                        </div>
                      </>
                    ) : (<></>)}




                    {singleQuery.extraDocs?.map((doc, index) => (
                      <div className="finanicial_details_single">
                        <div className="upload-btn-wrapper">
                          <a href={doc.url} download="file_name.extension" target='_blank'>
                            <button className="file_upload_btn form_input_box">
                              <span>
                                {doc.url.substring(doc.url.lastIndexOf('/') + 11)}
                              </span>
                              <img
                                src={download}
                                alt="upload"
                                className="img-fluid"
                              />
                            </button>
                          </a>
                          {/* <input type="file" className="form_input_box" readOnly /> */}
                        </div>
                        <div className='Incomplete_complete_status'>
                          <button
                            onClick={() => { handleQueryDocDelete(singleQuery, doc._id) }}
                          >
                            <span className="table_pending_btn">Delete</span>
                          </button>
                        </div>
                      </div>
                    ))}
                    <div className='query_details_Loan_status_div'>
                      <h3>
                        Status :<span>  {singleQuery?.status === 0 ? <span className="">Pending</span> :
                          singleQuery?.status === 1 ? <span style={{ color: "white" }} className=" table_approved_btn">Completed</span> : ""}</span>
                      </h3>
                    </div>
                    {singleQuery.status != 1 ? (<div className='loan_approve_message_btn'>
                      <div className='login_register_btn_div'>
                        {isSendTrue ? (
                          <Link
                            to='#'
                            onClick={UpdateQuery}
                            className='blue_btn login_register_btn'
                          >
                            Send
                          </Link>

                        ) : (
                          <Link
                            to='#'
                            className='blue_btn login_register_btn'
                          >
                            <div class="spinner-border text-primary" role="status">
                              <span class="sr-only"></span>
                            </div>
                          </Link>
                        )}
                      </div>
                    </div>) : (<></>)}
                  </div>
                </div>

                <div className='col-xl-5 col-lg-12 col-md-12 col-sm-12 profile_details_main_section d-none'>
                  <h3 className='margin_top_space'>
                    Financial Details <span> (Upload when month change)</span>
                  </h3>
                  <div>
                    <div className="">


                      {/* AuditedStatement */}
                      <div className="finanicial_details_single">

                        <div className="custom_file_upload_main">
                          <label htmlFor="AuditedStatement" className="file-input-button">
                            {kycPayloadFanancial.financial_stat ? (
                              <div className="file_upload_flex">
                                <span> {kycPayloadFanancial.financial_stat ?? borrowerData.financial_details.financial_stat.url.split('/').pop()}  </span>
                                <img src={upload} alt="upload" className="img-fluid" />
                              </div>
                            ) : (
                              <div className="file_upload_flex">
                                {borrowerData?.financial_details?.financial_stat?.url ? (
                                  <div className="file_upload_flex">
                                    <span> {kycPayloadFanancial.financial_stat ?? borrowerData?.financial_details?.financial_stat?.url.split('/').pop()}</span>
                                    <a href={borrowerData?.financial_details?.financial_stat?.url} download="file_name.extension" target='_blank'> <img src={download} alt="upload" className="img-fluid download_img" /></a>
                                  </div>
                                ) : (
                                  <div className="file_upload_flex">
                                    <span>Upload Audited Financial statements for last 3 years</span>
                                    <img src={upload} alt="upload" className="img-fluid" />
                                  </div>
                                )}
                              </div>
                            )}
                          </label>
                          <input
                            type="file"
                            id="AuditedStatement"
                            style={{ display: 'none' }}
                            required
                            onChange={(e) => handleFileUpload(e, setfinancial_stat, "financial_stat")}
                          />
                        </div>
                        <div className='Incomplete_complete_status'>
                          <button
                            onClick={handleClickAuditedCheck}
                            disabled={AuditedCheckActive}
                            style={{ opacity: AuditedCheckActive ? 1 : 0.4 }}
                          >
                            <img src={right} alt='cross' className='img-fluid' />
                          </button>
                          <button
                            onClick={handleClickAuditedCross}
                            disabled={AuditedcrossActive}
                            style={{ opacity: AuditedcrossActive ? 1 : 0.4 }}
                          >
                            <img src={cross} alt='cross' className='img-fluid' />
                          </button>
                        </div>
                      </div>
                      {borrowerData?.financial_details?.financial_stat?.url ? (
                        <h5 className='after_file_uploaded'> Audited Financial statements Uploaded</h5>
                      ) : ""}



                      {/* GSTLast */}
                      <div className="finanicial_details_single">
                        <div className="custom_file_upload_main">
                          <label htmlFor="GSTLast" className="file-input-button">
                            {kycPayloadFanancial.gst_filling ? (
                              <div className="file_upload_flex">
                                <span> {kycPayloadFanancial.gst_filling ?? borrowerData.financial_details.gst_filling.url.split('/').pop()}  </span>
                                <img src={upload} alt="upload" className="img-fluid" />
                              </div>
                            ) : (
                              <div className="file_upload_flex">
                                {borrowerData?.financial_details?.gst_filling?.url ? (
                                  <div className="file_upload_flex">
                                    <span> {kycPayloadFanancial.gst_filling ?? borrowerData?.financial_details?.gst_filling?.url.split('/').pop()}</span>
                                    <a href={borrowerData?.financial_details?.gst_filling?.url} download="file_name.extension" target='_blank'> <img src={download} alt="upload" className="img-fluid download_img" /></a>
                                  </div>
                                ) : (
                                  <div className="file_upload_flex">
                                    <span>Upload GST Filing for last 12 months</span>
                                    <img src={upload} alt="upload" className="img-fluid" />
                                  </div>
                                )}
                              </div>
                            )}
                          </label>
                          <input
                            type="file"
                            id="GSTLast"
                            style={{ display: 'none' }}
                            required
                            onChange={(e) => handleFileUpload(e, setGSTLast, "gst_filling")}
                          />
                        </div>
                        <div className='Incomplete_complete_status'>
                          <button
                            onClick={handleClickGSTCheck}
                            disabled={GSTCheckActive}
                            style={{ opacity: GSTCheckActive ? 1 : 0.4 }}
                          >
                            <img src={right} alt='cross' className='img-fluid' />
                          </button>
                          <button
                            onClick={handleClickGSTCross}
                            disabled={GSTcrossActive}
                            style={{ opacity: GSTcrossActive ? 1 : 0.4 }}
                          >
                            <img src={cross} alt='cross' className='img-fluid' />
                          </button>
                        </div>
                      </div>
                      {borrowerData?.financial_details?.gst_filling?.url ? (
                        <h5 className='after_file_uploaded'> GST Filing Uploaded</h5>
                      ) : ""}


                      {/* BankStatement */}
                      <div className="finanicial_details_single">
                        <div className="custom_file_upload_main">
                          <label htmlFor="BankStatement" className="file-input-button">
                            {kycPayloadFanancial.bank_statment_24 ? (
                              <div className="file_upload_flex">
                                <span> {kycPayloadFanancial.bank_statment_24 ?? borrowerData.financial_details.bank_statment_24.url.split('/').pop()}  </span>
                                <img src={upload} alt="upload" className="img-fluid" />
                              </div>
                            ) : (
                              <div className="file_upload_flex">
                                {borrowerData?.financial_details?.bank_statment_24?.url ? (
                                  <div className="file_upload_flex">
                                    <span> {kycPayloadFanancial.bank_statment_24 ?? borrowerData?.financial_details?.bank_statment_24?.url.split('/').pop()}</span>
                                    <a href={borrowerData?.financial_details?.bank_statment_24?.url} download="file_name.extension" target='_blank'> <img src={download} alt="upload" className="img-fluid download_img" /></a>
                                  </div>
                                ) : (
                                  <div className="file_upload_flex">
                                    <span>Upload all Bank Statement of last 24 months</span>
                                    <img src={upload} alt="upload" className="img-fluid" />
                                  </div>
                                )}
                              </div>
                            )}
                          </label>
                          <input
                            type="file"
                            id="BankStatement"
                            style={{ display: 'none' }}
                            required
                            onChange={(e) => handleFileUpload(e, setBankStatement, "bank_statment_24")}
                          />



                        </div>
                        <div className='Incomplete_complete_status'>
                          <button
                            onClick={handleClickBankCheck}
                            disabled={BankCheckActive}
                            style={{ opacity: BankCheckActive ? 1 : 0.4 }}
                          >
                            <img src={right} alt='cross' className='img-fluid' />
                          </button>
                          <button
                            onClick={handleClickBankCross}
                            disabled={BankcrossActive}
                            style={{ opacity: BankcrossActive ? 1 : 0.4 }}
                          >
                            <img src={cross} alt='cross' className='img-fluid' />
                          </button>
                        </div>
                      </div>
                      {borrowerData?.financial_details?.bank_statment_24?.url ? (
                        <h5 className='after_file_uploaded'> All Bank Statement are Uploaded</h5>
                      ) : ""}

                      {/* provisionalbalance */}
                      <div className="finanicial_details_single">
                        <div className="custom_file_upload_main">
                          <label htmlFor="provisionalbalance" className="file-input-button">
                            {kycPayloadFanancial.provisional_sheet ? (
                              <div className="file_upload_flex">
                                <span> {kycPayloadFanancial.provisional_sheet ?? borrowerData.financial_details.provisional_sheet.url.split('/').pop()}  </span>
                                <img src={upload} alt="upload" className="img-fluid" />
                              </div>
                            ) : (
                              <div className="file_upload_flex">
                                {borrowerData?.financial_details?.provisional_sheet?.url ? (
                                  <div className="file_upload_flex">
                                    <span> {kycPayloadFanancial.provisional_sheet ?? borrowerData?.financial_details?.provisional_sheet?.url.split('/').pop()}</span>
                                    <a href={borrowerData?.financial_details?.provisional_sheet?.url} download="file_name.extension" target='_blank'> <img src={download} alt="upload" className="img-fluid download_img" /></a>
                                  </div>
                                ) : (
                                  <div className="file_upload_flex">
                                    <span> Upload provisional balance sheet for the current year</span>
                                    <img src={upload} alt="upload" className="img-fluid" />
                                  </div>
                                )}
                              </div>
                            )}
                          </label>
                          <input
                            type="file"
                            id="provisionalbalance"
                            style={{ display: 'none' }}
                            required
                            onChange={(e) => handleFileUpload(e, setprovisionalbalance, "provisional_sheet")}
                          />
                        </div>
                        <div className='Incomplete_complete_status'>
                          <button
                            onClick={handleClickProvisionalCheck}
                            disabled={ProvisionalCheckActive}
                            style={{ opacity: ProvisionalCheckActive ? 1 : 0.4 }}
                          >
                            <img src={right} alt='cross' className='img-fluid' />
                          </button>
                          <button
                            onClick={handleClickProvisionalCross}
                            disabled={ProvisionalcrossActive}
                            style={{ opacity: ProvisionalcrossActive ? 1 : 0.4 }}
                          >
                            <img src={cross} alt='cross' className='img-fluid' />
                          </button>
                        </div>
                      </div>
                      {borrowerData?.financial_details?.provisional_sheet?.url ? (
                        <h5 className='after_file_uploaded'>Provisional balance sheet Uploaded</h5>
                      ) : ""}

                      {/* ITR */}
                      <div className="finanicial_details_single">
                        <div className="custom_file_upload_main">
                          <label htmlFor="ITR" className="file-input-button">
                            {kycPayloadFanancial.itr_acknowledgement ? (
                              <div className="file_upload_flex">
                                <span> {kycPayloadFanancial.itr_acknowledgement ?? borrowerData.financial_details.itr_acknowledgement.url.split('/').pop()}  </span>
                                <img src={upload} alt="upload" className="img-fluid" />
                              </div>
                            ) : (
                              <div className="file_upload_flex">
                                {borrowerData?.financial_details?.itr_acknowledgement?.url ? (
                                  <div className="file_upload_flex">
                                    <span> {kycPayloadFanancial.itr_acknowledgement ?? borrowerData?.financial_details?.itr_acknowledgement?.url.split('/').pop()}</span>
                                    <a href={borrowerData?.financial_details?.itr_acknowledgement?.url} download="file_name.extension" target='_blank'> <img src={download} alt="upload" className="img-fluid download_img" /></a>
                                  </div>
                                ) : (
                                  <div className="file_upload_flex">
                                    <span>  Upload ITR acknowledgement form for last 2 years</span>
                                    <img src={upload} alt="upload" className="img-fluid" />
                                  </div>
                                )}
                              </div>
                            )}
                          </label>
                          <input
                            type="file"
                            id="ITR"
                            style={{ display: 'none' }}
                            required
                            onChange={(e) => handleFileUpload(e, setITR, "itr_acknowledgement")}
                          />
                        </div>
                        <div className='Incomplete_complete_status'>
                          <button
                            onClick={handleClickITRCheck}
                            disabled={ITRCheckActive}
                            style={{ opacity: ITRCheckActive ? 1 : 0.4 }}
                          >
                            <img src={right} alt='cross' className='img-fluid' />
                          </button>
                          <button
                            onClick={handleClickITRCross}
                            disabled={ITRcrossActive}
                            style={{ opacity: ITRcrossActive ? 1 : 0.4 }}
                          >
                            <img src={cross} alt='cross' className='img-fluid' />
                          </button>
                        </div>
                      </div>
                      {borrowerData?.financial_details?.itr_acknowledgement?.url ? (
                        <h5 className='after_file_uploaded'>ITR acknowledgement Uploaded</h5>
                      ) : ""}

                      {/* Debtserviced */}
                      <div className="finanicial_details_single">
                        <div className="custom_file_upload_main">
                          <label htmlFor="Debtserviced" className="file-input-button">
                            {kycPayloadFanancial.debt_service ? (
                              <div className="file_upload_flex">
                                <span> {kycPayloadFanancial.debt_service ?? borrowerData.financial_details.debt_service.url.split('/').pop()}  </span>
                                <img src={upload} alt="upload" className="img-fluid" />
                              </div>
                            ) : (
                              <div className="file_upload_flex">
                                {borrowerData?.financial_details?.debt_service?.url ? (
                                  <div className="file_upload_flex">
                                    <span> {kycPayloadFanancial.debt_service ?? borrowerData?.financial_details?.debt_service?.url.split('/').pop()}</span>
                                    <a href={borrowerData?.financial_details?.debt_service?.url} download="file_name.extension" target='_blank'> <img src={download} alt="upload" className="img-fluid download_img" /></a>
                                  </div>
                                ) : (
                                  <div className="file_upload_flex">
                                    <span>  Upload ITR acknowledgement form for last 2 years</span>
                                    <img src={upload} alt="upload" className="img-fluid" />
                                  </div>
                                )}
                              </div>
                            )}
                          </label>
                          <input
                            type="file"
                            id="Debtserviced"
                            style={{ display: 'none' }}
                            required
                            onChange={(e) => handleFileUpload(e, setDebtserviced, "debt_service")}
                          />
                        </div>
                        <div className='Incomplete_complete_status'>
                          <button
                            onClick={handleClickDebetCheck}
                            disabled={DebetCheckActive}
                            style={{ opacity: DebetCheckActive ? 1 : 0.4 }}
                          >
                            <img src={right} alt='cross' className='img-fluid' />
                          </button>
                          <button
                            onClick={handleClickDebetCross}
                            disabled={DebetcrossActive}
                            style={{ opacity: DebetcrossActive ? 1 : 0.4 }}
                          >
                            <img src={cross} alt='cross' className='img-fluid' />
                          </button>
                        </div>
                      </div>
                      {borrowerData?.financial_details?.debt_service?.url ? (
                        <h5 className='after_file_uploaded'>ITR acknowledgement Uploaded</h5>
                      ) : ""}

                      {/* mis_additional */}
                      <div className="finanicial_details_single">

                        <div className="custom_file_upload_main">
                          <label htmlFor="MIS" className="file-input-button">
                            {kycPayloadFanancial.mis_additional ? (
                              <div className="file_upload_flex">
                                <span> {kycPayloadFanancial.mis_additional ?? borrowerData.financial_details.mis_additional.url.split('/').pop()}  </span>
                                <img src={upload} alt="upload" className="img-fluid" />
                              </div>
                            ) : (
                              <div className="file_upload_flex">
                                {borrowerData?.financial_details?.mis_additional?.url ? (
                                  <div className="file_upload_flex">
                                    <span> {kycPayloadFanancial.mis_additional ?? borrowerData?.financial_details?.mis_additional?.url.split('/').pop()}</span>
                                    <a href={borrowerData?.financial_details?.mis_additional?.url} download="file_name.extension" target='_blank'> <img src={download} alt="upload" className="img-fluid download_img" /></a>
                                  </div>
                                ) : (
                                  <div className="file_upload_flex">
                                    <span>  Upload MIS and additional Documents (optional)</span>
                                    <img src={upload} alt="upload" className="img-fluid" />
                                  </div>
                                )}
                              </div>
                            )}
                          </label>
                          <input
                            type="file"
                            id="MIS"
                            style={{ display: 'none' }}
                            required
                            onChange={(e) => handleFileUpload(e, setMIS, "mis_additional")}
                          />
                        </div>
                        <div className='Incomplete_complete_status'>
                          <button
                            onClick={handleClickMISCheck}
                            disabled={MISCheckActive}
                            style={{ opacity: MISCheckActive ? 1 : 0.4 }}
                          >
                            <img src={right} alt='cross' className='img-fluid' />
                          </button>
                          <button
                            onClick={handleClickMISCross}
                            disabled={MIScrossActive}
                            style={{ opacity: MIScrossActive ? 1 : 0.4 }}
                          >
                            <img src={cross} alt='cross' className='img-fluid' />
                          </button>
                        </div>
                      </div>
                      {borrowerData?.financial_details?.mis_additional?.url ? (
                        <h5 className='after_file_uploaded'>Upload MIS and additional Documents Uploaded</h5>
                      ) : ""}


                    </div>
                    {/* <div className="dashboard_add_new_btn">
                      <button className="" onClick={() => { Financial_Details() }}>
                        Submit
                      </button>
                    </div> */}
                  </div>





                  {/* <div>
                    <div className='finanicial_details_single'>
                      <div className='upload-btn-wrapper'>
                        <button className='file_upload_btn form_input_box'>
                          <span>
                            Upload Audited Financial statements for last 3 years
                          </span>
                          <img
                            src={upload}
                            alt='upload'
                            className='img-fluid'
                          />
                        </button>
                        <input type='file' className='form_input_box' />
                      </div>
                      <div className='Incomplete_complete_status red_green_status_main'>
                        <button className='Incomplete'>
                          <img src={cross} alt='cross' className='img-fluid' />
                          Incomplete
                        </button>
                      </div>
                    </div>

                    <div className='finanicial_details_single'>
                      <div className='upload-btn-wrapper'>
                        <button className='file_upload_btn form_input_box'>
                          <span> Upload GST Filing for last 12 months</span>
                          <img
                            src={upload}
                            alt='upload'
                            className='img-fluid'
                          />
                        </button>
                        <input type='file' className='form_input_box' />
                      </div>
                      <div className='Incomplete_complete_status red_green_status_main'>
                        <button className='Incomplete'>
                          <img src={cross} alt='cross' className='img-fluid' />
                          Incomplete
                        </button>
                      </div>
                    </div>

                    <div className='finanicial_details_single'>
                      <div className='upload-btn-wrapper'>
                        <button className='file_upload_btn form_input_box'>
                          <span> Upload Bank Statement of last 12 months</span>
                          <img
                            src={upload}
                            alt='upload'
                            className='img-fluid'
                          />
                        </button>
                        <input type='file' className='form_input_box' />
                      </div>
                      <div className='Incomplete_complete_status red_green_status_main'>
                        <button className='Incomplete Complete'>
                          <img src={check} alt='cross' className='img-fluid' />
                          Complete
                        </button>
                      </div>
                    </div>

                    <div className='finanicial_details_single'>
                      <div className='upload-btn-wrapper'>
                        <button className='file_upload_btn form_input_box'>
                          <span>
                            Upload provisional balance sheet for the current
                            year
                          </span>
                          <img
                            src={upload}
                            alt='upload'
                            className='img-fluid'
                          />
                        </button>
                        <input type='file' className='form_input_box' />
                      </div>
                      <div className='Incomplete_complete_status red_green_status_main'>
                        <button className='Incomplete Complete'>
                          <img src={check} alt='cross' className='img-fluid' />
                          Complete
                        </button>
                      </div>
                    </div>

                    <div className='finanicial_details_single'>
                      <div className='upload-btn-wrapper'>
                        <button className='file_upload_btn form_input_box'>
                          <span>
                            Upload ITR acknowledgement form for last 2 years
                          </span>
                          <img
                            src={upload}
                            alt='upload'
                            className='img-fluid'
                          />
                        </button>
                        <input type='file' className='form_input_box' />
                      </div>
                      <div className='Incomplete_complete_status red_green_status_main'>
                        <button className='Incomplete'>
                          <img src={cross} alt='cross' className='img-fluid' />
                          Incomplete
                        </button>
                      </div>
                    </div>

                    <div className='finanicial_details_single'>
                      <div className='upload-btn-wrapper'>
                        <button className='file_upload_btn form_input_box'>
                          <span>
                            Upload Debt serviced during the current FY
                            (Principal + interest repayment during the year)
                          </span>
                          <img
                            src={upload}
                            alt='upload'
                            className='img-fluid'
                          />
                        </button>
                        <input type='file' className='form_input_box' />
                      </div>
                      <div className='Incomplete_complete_status red_green_status_main'>
                        <button className='Incomplete'>
                          <img src={cross} alt='cross' className='img-fluid' />
                          Incomplete
                        </button>
                      </div>
                    </div>

                    <div className='finanicial_details_single'>
                      <div className='upload-btn-wrapper'>
                        <button className='file_upload_btn form_input_box'>
                          <span>Upload MIS</span>
                          <img
                            src={upload}
                            alt='upload'
                            className='img-fluid'
                          />
                        </button>
                        <input type='file' className='form_input_box' />
                      </div>
                      <div className='Incomplete_complete_status red_green_status_main'>
                        <button className='Incomplete'>
                          <img src={cross} alt='cross' className='img-fluid' />
                          Incomplete
                        </button>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </section>
          </section>
        </div>
      </BorrowerDashboardMain>
    </>
  )
}

export default QueryDetails
