import React, { useEffect, useState } from 'react'
import search from '../assets/imgs/icosn/search.svg'
import msg from '../assets/imgs/icosn/msg.svg'
import notification from '../assets/imgs/icosn/Notfication.svg'

import { Link, NavLink } from 'react-router-dom'
import { useContext } from 'react'
import { globalContext } from '../../App'
const Header = () => {

  const notifyData = useContext(globalContext);
  // console.log(notifyData.state.notifyData)
  // console.log("state", state)
  // ============state for form============
  const [profileData, setprofileData] = useState(null)
  const userToken = localStorage.getItem('borroweruserid')
  // ============Profile API============
  const profileAPI = () => {


    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')

    var raw = JSON.stringify({
      userId: userToken
    })

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    }

    fetch('https://bizfinn.co.in/getProfile', requestOptions)
      .then(response => response.json())
      .then(result => {
        setprofileData(result)
      })
      .catch(error => console.log('error', error))
  }

  // ============Profile API call============
  useEffect(() => {
    profileAPI()
    // console.log('profileData:---', profileData)
  }, [])



  // ============Read Notification API============

  const ReadNotification = () => {

    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/readNotification/${userToken}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
      })
      .catch(error => console.log('error', error));
  }


  const [isLoggedIn, setisLoggedIn] = useState(true)

  const clear_ID = () => {
    localStorage.removeItem('borroweruserid');
  }

  // ============================
  // ====chat active status======
  // ============================
  useEffect(() => {
    const currentURL = window.location.href
    const parts = currentURL.split('/');
    const lastPart = parts[parts.length - 2];
    console.log(lastPart);
    if (lastPart == 'borrower-profile-share') {
      setisLoggedIn(false)
    }


    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "active": 1
    });

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/setActiveStatus/${userToken}`, requestOptions)
      .then(response => response.json())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }, [])


  return (
    <>
      <section className="header_main_div">
        <div className="header_search_box">
          {/* <img src={search} alt="search" className="img-fluid" />{' '}
          <input type="search" placeholder="Search for the Deals" /> */}
        </div>
        <div className="header_right_side_div" style={{minWidth:'200px'}}>

          {isLoggedIn ? (
            <>
              <nav className='header_right_side_div_nav'>
                <div className="border_right">

                  <NavLink
                    exact
                    to="/borrower-chat"
                    className="header_right_side_div_nav_link"

                    activeClassName="active_link"
                  >
                    <img src={msg} alt="search" className="img-fluid" />{' '}
                  </NavLink>
                </div>
                <div className="border_right ">
                  <NavLink
                    exact
                    to="/borrower_notification"
                    onClick={ReadNotification}
                    className="header_right_side_div_nav_link header_pos_rel"
                    activeClassName="active_link"
                  >
                    <img src={notification} alt="search" className="img-fluid" />{' '}
                    {notifyData?.state?.notifyData <= 0 ? ' ' : <span className='header_badge'>{notifyData?.state?.notifyData}</span>}
                  </NavLink>
                </div>

                <div className="header_profile_btn">
                  {profileData?.data ? (
                    <Link to="/borrower_profile" className="">
                      <img src={
                        profileData &&
                        profileData?.data[0] &&
                        profileData?.data[0]?.avatar
                      } alt="" className="img-fluid" />{' '}
                      <span>{profileData &&
                        profileData?.data[0] &&
                        profileData?.data[0]?.name}</span>
                    </Link>

                  ) : (
                    <></>
                  )}

                </div>
              </nav></>
          ) : (<>
            <nav className='header_right_side_div_nav'style={{marginTop:"20px" , marginRight:'20px'}} >
              {/* <NavLink
                exact
                to="/borrower-register"
                className="sidebar_link "
                activeClassName="active_link"
              > */}
              <a href='https://loans.bizfinn.com/borrower-register'>
              <span className="sidebar_link "> Join Us Now</span>

              </a>
              {/* </NavLink> */}
            </nav>
          </>)}



        </div>
      </section>
    </>
  )
}

export default Header
